export default (str, toTwoDecimalPlaces = false) => {
  const hasDecimal = str.toString().includes('.');
  const [value, decimal] = str.toString().split('.');
  const formattedValue = value
    .replace(/[^0-9]/g, '')
    .replace(/,/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const formattedDecimal = (
    hasDecimal ? (decimal.length > 2 ? decimal.substring(0, 2) : decimal) : ''
  ).replace(/[^0-9]/g, '');

  return `${formattedValue}${hasDecimal ? '.' : ''}${
    toTwoDecimalPlaces && formattedDecimal.length === 1
      ? `${formattedDecimal}0`
      : formattedDecimal
  }`;
};
