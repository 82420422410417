export default str => {
  const cleaned = str.toString().replace(/\D/g, '');

  if (cleaned.length === 0) {
    return '';
  } else if (cleaned.length > 0 && cleaned.length < 4) {
    return `(${cleaned.substring(0, 3)}`;
  } else if (cleaned.length > 3 && cleaned.length < 7) {
    return `(${cleaned.substring(0, 3)}) ${cleaned.substring(3, 6)}`;
  } else {
    return `(${cleaned.substring(0, 3)}) ${cleaned.substring(
      3,
      6
    )}-${cleaned.substring(6, 10)}`;
  }
};
