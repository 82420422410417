import React from 'react';
import PropTypes from 'prop-types';
import DealerCardStyles from './styled.js';
import Link from '../Link';
import MapPinIcon from '../Icons/MapPin';
import PhoneIcon from '../Icons/Phone';
import phoneFormatter from '../../../util/formatters/Phone';
import generateGeoMapsLink from '../../../util/navigator/generateGeoMapsLink';

const DealerCard = ({
  dealerName,
  phoneNumber,
  streetAddress,
  city,
  stateProvince,
  postalCode,
  distanceFromPostalCodeMidpoint,
  url,
  latitude,
  longitude,
  style = {},
}) => (
  <DealerCardStyles className='dealer_card' style={style}>
    <Link to={url} isExternal={true} newTab={true} className='dealer_name'>
      {dealerName && dealerName.replace(/-/, ' ').toLowerCase()}
    </Link>
    <div className='rows'>
      <div className='row'>
        <PhoneIcon />
        <a href={`tel:${phoneNumber}`}>{phoneFormatter(phoneNumber)}</a>
      </div>
      <div className='row'>
        <MapPinIcon />
        <a href={generateGeoMapsLink(latitude, longitude)} target='_blank'>
          {streetAddress && streetAddress.toLowerCase()}
          <br />
          {city && city.toLowerCase()}, {stateProvince} {postalCode}
        </a>
      </div>
    </div>
    <p className='type-h6 dealer-card-distance'>
      Distance:{' '}
      {parseFloat(parseFloat(distanceFromPostalCodeMidpoint).toFixed(2))} miles
    </p>
  </DealerCardStyles>
);

DealerCard.propTypes = {
  dealerName: PropTypes.string,
  phoneNumber: PropTypes.string,
  streetAddress: PropTypes.string,
  city: PropTypes.string,
  stateProvince: PropTypes.string,
  postalCode: PropTypes.string,
  distanceFromPostalCodeMidpoint: PropTypes.number,
  url: PropTypes.string,
  latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
};

export default DealerCard;
