import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Input from './Input';

const Dropdown = ({
  label = '',
  error = false,
  touched = false,
  className = '',
  errorInDropdown = false,
  note = '',
  ...props
}) => {
  const [filled, setFilled] = useState(props.value || touched);
  const [open, setOpen] = useState(false);
  const handleChange = e => {
    setFilled(true);
    props.onChange(e);
  };

  return (
    <label
      className={`${className} ${touched && error ? 'error' : ''} ${
        filled ? 'filled' : ''
      } ${open ? 'open' : ''} input-dropdown`}
    >
      {label && (
        <p className='input-label' id={props['aria-describedby'] || null}>
          {label}
        </p>
      )}
      <Input
        type='text'
        {...props}
        open={open}
        setOpen={setOpen}
        setFilled={setFilled}
        onChange={handleChange}
        error={errorInDropdown && error}
        label={label}
      />
      {error && !errorInDropdown && touched && (
        <p className='input-error'>{error}</p>
      )}
      {note && (!error || !touched) && <p className='input-note'>{note}</p>}
    </label>
  );
};

Dropdown.propTypes = {
  label: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  touched: PropTypes.bool,
  className: PropTypes.string,
  errorInDropdown: PropTypes.bool,
  note: PropTypes.string,
};

export default Dropdown;
