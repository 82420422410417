import React from 'react';
import PropTypes from 'prop-types';
import DealerSearchStyles from './DealerSearch.styled.js';
import SearchIcon from '../Shared/Icons/Search';
import LoadingIcon from '../Shared/Icons/Loading';
import Dropdown from '../Shared/Inputs/Dropdown';
import noop from '../../util/noop';

const DealerSearch = ({
  search = '',
  suggestions = [],
  touched = false,
  isSearching = false,
  error = false,
  onChange = noop,
  onBlur = noop,
  onSubmit = noop,
  label = '',
}) => {
  return (
    <DealerSearchStyles>
      <form onSubmit={onSubmit}>
        <section className='search'>
          <Dropdown
            label={label}
            onChange={onChange}
            value={search}
            values={suggestions}
            touched={touched}
            onBlur={onBlur}
            showFormatted={false}
            hasDropdownIcon={false}
            error={error}
            errorInDropdown={true}
          />
          {isSearching && <LoadingIcon />}

          {!isSearching && <SearchIcon />}
        </section>
      </form>
    </DealerSearchStyles>
  );
};

DealerSearch.propTypes = {
  value: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  touched: PropTypes.bool,
  onResults: PropTypes.func,
};

export default DealerSearch;
