import styled from 'styled-components';

export default styled.div`
  max-width: ${props => props.theme.maxWidths.hugSearch};
  margin: auto;
  padding: 1.6875rem 0 1.1875rem;
  position: relative;
  z-index: 10;

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 1.6875rem 0 2rem;
  }

  @media (min-width: ${props => props.theme.breakpoints.desktop}) {
    padding: 2.075rem 0 2.75rem;
  }

  .search {
    box-shadow: ${props => props.theme.shadows.low};
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: row;

    label {
      flex: 1 1 auto;
      box-sizing: border-box;
      position: relative;
      background: ${props => props.theme.colors.white};

      &.filled {
        .input-label {
          font-size: ${props => props.theme.type.paragraph.xs};
          letter-spacing: ${props => props.theme.letterSpacing.paragraph};
          line-height: ${props => props.theme.lineHeights.paragraph.xs};
          transform: translateY(-0.75em);
        }
      }

      &.open {
        .input_wrapper {
          .values {
            max-height: 200px;
          }
        }
      }

      &.error {
        input,
        textarea {
          border-color: ${props => props.theme.colors.vmfRed};
        }

        .input-error {
          opacity: 1;
          transform: none;
        }
      }
    }

    input {
      color: ${props => props.theme.colors.black};
      box-sizing: border-box;
      width: 100%;
      height: 52px;
      font-size: ${props => props.theme.type.paragraph.default};
      line-height: ${props => props.theme.lineHeights.paragraph.default};
      letter-spacing: ${props => props.theme.letterSpacing.paragraph};
      border-radius: 0;
      border: solid 1px ${props => props.theme.colors.grey2};
      padding: 1.1875rem 0.75rem 0.3125rem;
      font-family: ${props => props.theme.fonts.primary};
      transition: all 0.4s ease-in-out;
      background: ${props => props.theme.colors.white};

      &[type='number']::-webkit-inner-spin-button,
      &[type='number']::-webkit-outer-spin-button {
        appearance: none;
      }

      &:focus,
      &:active {
        outline: none;
        border-radius: 0;
        border: solid 1px ${props => props.theme.colors.vmfBlueLight};
      }

      + p {
        opacity: 0.25;
      }
    }

    .input-label {
      font-size: ${props => props.theme.type.paragraph.default};
      line-height: ${props => props.theme.type.paragraph.default};
      letter-spacing: ${props => props.theme.type.paragraph};
      color: ${props => props.theme.colors.grey1};
      position: absolute;
      top: 0.25rem;
      left: 0.75rem;
      transition: all 0.4s ease-in-out;
      z-index: 1;
    }

    .input-error {
      position: absolute;
      top: calc(100% - 0.75em);
      font-size: ${props => props.theme.type.paragraph.xs};
      line-height: ${props => props.theme.lineHeights.paragraph.xs};
      letter-spacing: ${props => props.theme.letterSpacing.paragraph};
      color: ${props => props.theme.colors.vmfRed};
      opacity: 0;
      transform: translateY(-10px);
      transition: all 0.4s ease-in-out;
    }

    .input_wrapper {
      color: ${props => props.theme.colors.black};
      position: relative;
      width: 100%;

      .values {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        overflow: auto;
        background: ${props => props.theme.colors.white};
        box-shadow: ${props => props.theme.shadows.low};
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        max-height: 0;
        border-radius: 0 0 3px 3px;
        z-index: 2;
        transition: all 0.4s ease-in-out;

        li {
          list-style: none;
          padding: 0;

          &:first-child {
            margin-top: 0.5rem;
          }

          &:last-child {
            margin-bottom: 0.5rem;
          }

          button {
            background: transparent;
            border: none;
            width: 100%;
            text-align: left;
            padding: 0.5rem 1rem;
            cursor: pointer;

            p {
              margin: 0;
            }
          }
        }
      }
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;
      position: absolute;
      top: 0.75rem;
      right: 0.75rem;
      color: ${props => props.theme.colors.vmfBlue};
    }
  }

  .note {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
