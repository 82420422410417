import axios from 'axios';
import { getBaseApiUrl } from '../util/config';

export const getSuggestions = async (value = '') =>
  await axios.get(
    `${getBaseApiUrl()}/location/geocode/autocomplete/${encodeURIComponent(
      value
    )}`
  );

export const getGeocode = async () =>
  await axios.get(`${getBaseApiUrl()}/location/geocode/`);
