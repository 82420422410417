import React from 'react';

export default () => (
  <svg
    width='17'
    height='17'
    viewBox='0 0 17 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.66862 1.38802C7.25413 1.38802 5.89758 1.94992 4.89738 2.95012C3.89719 3.95031 3.33529 5.30687 3.33529 6.72135C3.33529 8.78673 4.67911 10.7909 6.15259 12.3462C6.87569 13.1095 7.60107 13.7343 8.1464 14.1686C8.34936 14.3302 8.52664 14.4648 8.66862 14.5697C8.8106 14.4648 8.98788 14.3302 9.19084 14.1686C9.73618 13.7343 10.4615 13.1095 11.1847 12.3462C12.6581 10.7909 14.002 8.78673 14.002 6.72135C14.002 5.30687 13.4401 3.95031 12.4399 2.95012C11.4397 1.94992 10.0831 1.38802 8.66862 1.38802ZM8.66862 15.388C8.29882 15.9427 8.29865 15.9426 8.29846 15.9425L8.29655 15.9412L8.29211 15.9382L8.27683 15.9279C8.26385 15.9191 8.24534 15.9064 8.22166 15.89C8.17431 15.8572 8.10626 15.8094 8.02048 15.7475C7.84898 15.6236 7.60619 15.4428 7.31584 15.2116C6.73617 14.75 5.96155 14.0832 5.18465 13.2632C3.65813 11.6519 2.00195 9.32265 2.00195 6.72135C2.00195 4.95324 2.70433 3.25755 3.95457 2.00731C5.20482 0.757066 6.90051 0.0546875 8.66862 0.0546875C10.4367 0.0546875 12.1324 0.757066 13.3827 2.00731C14.6329 3.25755 15.3353 4.95324 15.3353 6.72135C15.3353 9.32265 13.6791 11.6519 12.1526 13.2632C11.3757 14.0832 10.6011 14.75 10.0214 15.2116C9.73105 15.4428 9.48826 15.6236 9.31676 15.7475C9.23098 15.8094 9.16293 15.8572 9.11558 15.89C9.0919 15.9064 9.07339 15.9191 9.06041 15.9279L9.04513 15.9382L9.04069 15.9412L9.03928 15.9421C9.03909 15.9423 9.03842 15.9427 8.66862 15.388ZM8.66862 15.388L9.03842 15.9427C8.81449 16.092 8.52239 16.0918 8.29846 15.9425L8.66862 15.388Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.66862 5.38802C7.93224 5.38802 7.33529 5.98497 7.33529 6.72135C7.33529 7.45773 7.93224 8.05469 8.66862 8.05469C9.405 8.05469 10.002 7.45773 10.002 6.72135C10.002 5.98497 9.405 5.38802 8.66862 5.38802ZM6.00195 6.72135C6.00195 5.24859 7.19586 4.05469 8.66862 4.05469C10.1414 4.05469 11.3353 5.24859 11.3353 6.72135C11.3353 8.19411 10.1414 9.38802 8.66862 9.38802C7.19586 9.38802 6.00195 8.19411 6.00195 6.72135Z'
      fill='currentColor'
    />
  </svg>
);
