import axios from 'axios';
import { getBaseApiUrl } from '../util/config';

export const getDealers = async (postalCode = '') => {
  if (process.env.NODE_ENV !== 'production') {
    return await axios.get(
      `https://dev.cmhinfo.biz/WebServices/api/dealers/cityorpostalcode?radiusInMiles=100&includeIndependents=false&postalCode=${encodeURIComponent(
        postalCode
      )}`,
      {
        headers: {
          Accept: 'application/json',
        },
      }
    );
  }
  return await axios.get(
    `${getBaseApiUrl()}/dealers/cityorpostalcode?radiusInMiles=100&includeIndependents=false&postalCode=${encodeURIComponent(
      postalCode
    )}`
  );
};
