export default (latitude, longitude) => {
  const appleMapsUrl = `maps://maps.google.com/maps?daddr=${latitude},${longitude}&amp;11=`;
  const googleMapsUrl = `https://maps.google.com/maps?daddr=${latitude},${longitude}&amp;11=`;

  const navigatorHas = match =>
    navigator?.platform && navigator?.platform?.indexOf(match) !== -1;

  if (navigator) {
    if (navigator.platform) {
      if (
        navigatorHas('iPod') ||
        navigatorHas('iPad') ||
        navigatorHas('iPhone')
      ) {
        return appleMapsUrl;
      }
    }
  }

  return googleMapsUrl;
};
