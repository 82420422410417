import React, { useRef, forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import noop from '../../../util/noop';
import formatters from '../../../util/formatters';
import ChevronDownIcon from '../Icons/ChevronDown';

const Input = ({
  prefix = false,
  suffix = false,
  values = [],
  value = '',
  setFieldValue,
  onBlur = noop,
  label = '',
  touched,
  open,
  setOpen = noop,
  setFilled = noop,
  onChange,
  hasDropdownIcon = true,
  showFormatted = true,
  canSearch = true,
  isButton = false,
  error = false,
  formatter = false,
  decimalPlaces = false,
  ...props
}) => {
  const inputRef = useRef();
  const formattedValue =
    values.length && showFormatted
      ? values.find(
          option => option.value.toLowerCase() === value.toLowerCase()
        )?.label
      : value;
  const filteredValues = isButton
    ? values
    : values.filter(
        option =>
          option.label.toLowerCase().includes(value.toLowerCase()) ||
          option.value.toLowerCase().includes(value.toLowerCase())
      );
  const isOptionSelected = !!values.find(
    ({ value: optionValue }) => optionValue === value
  );
  const valuesToShow = isOptionSelected ? values : filteredValues;

  const handleFocus = e => {
    setOpen(true);
    setFilled(true);
  };

  const handleBlur = e => {
    if (values.length) {
      const selectedValue = values.find(
        option =>
          option.label.toLowerCase() === value.toLowerCase() ||
          option.value.toLowerCase() === value.toLowerCase()
      );

      if (selectedValue) {
        const newEvent = {
          ...e,
          value: selectedValue,
        };
        onBlur(newEvent);
      }

      setOpen(false);
      setFilled(!!selectedValue || !!value);

      setTimeout(() => {
        onBlur(e);
      }, 0);
    }

    onBlur(e);
  };

  const handleChange = e => {
    const formattedValue =
      formatter && typeof formatters[formatter] === 'function'
        ? formatters[formatter](e.target.value)
        : e.target.value;
    const newValue = decimalPlaces
      ? formattedValue.match(/\d?\.?(\d{1,2})?/)[0]
      : formattedValue;

    const newEvent = {
      ...e,
      target: {
        name: e.target.name,
        value: newValue,
      },
      value: newValue,
    };

    onChange(newEvent);
  };

  const handleValueClick = value => e => {
    e.preventDefault();
    e.stopPropagation();
    const newEvent = {
      ...e,
      target: {
        name: inputRef.current?.name,
        value,
      },
      value,
    };
    onChange(newEvent);
    setOpen(false);
    setFilled(true);
    setTimeout(() => {
      inputRef?.current?.blur();
    }, 100);
  };

  const handleKeyPress = e => {
    if (!canSearch) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <div className='input_wrapper'>
      {prefix && <p className='prefix'>{prefix}</p>}
      {(values.length > 0 && !isButton) || values.length == 0 ? (
        <>
          <input
            {...props}
            value={formattedValue}
            ref={inputRef}
            // onBlur={handleBlur}
            onFocus={handleFocus}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
          />
          {hasDropdownIcon && values.length > 0 && (
            <ChevronDownIcon className='input-dropdown_icon' />
          )}
        </>
      ) : (
        <button
          type='button'
          ref={inputRef}
          className='input-value'
          onClick={() => {
            setOpen(!open);
          }}
        >
          {formattedValue}
          <ChevronDownIcon className='input-dropdown_icon' />
        </button>
      )}
      {suffix && <p className='suffix'>{suffix}</p>}
      {error && (
        <>
          <div
            className='input-backdrop'
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setOpen(false);
              setFilled(!!value);
            }}
          />
          <ul className='values'>
            <li>
              <p>{error}</p>
            </li>
          </ul>
        </>
      )}
      {values.length > 0 && !error && (
        <>
          <div
            className='input-backdrop'
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setOpen(false);
              setFilled(!!value);
            }}
          />
          <ul
            className='values'
            id={`${props.name || ''}Results`}
            role='listbox'
            aria-label={`${props.label || ''} Results`}
          >
            {valuesToShow.map(({ label = '', value = '' }) => (
              <li key={value} role='option'>
                <button
                  type='button'
                  onClick={handleValueClick(value)}
                  tabIndex={open ? 0 : -1}
                  className='input-option'
                >
                  <p>{label}</p>
                </button>
              </li>
            ))}
          </ul>
        </>
      )}
      {values.length > 0 && !error && (
        <p
          aria-live='polite'
          role='status'
          style={{ position: 'fixed', left: '-9999px' }}
        >
          {values.length} results available. Use tab to hear options and enter
          to choose an option.
        </p>
      )}
    </div>
  );
};

Input.propTypes = {
  prefix: PropTypes.string,
  suffix: PropTypes.string,
};

export default Input;
